<template>
  <div class="report_wrapper my-4">

    <div v-if="!scExStudentExamPartSet.is_practice" class="report_body">

<!-- 「WA-1　 本テスト  .xd」より -->
<!--
【調整のコツ】
・「グループ_ALL」のサイズ（538.99 790.99）をブラウザで確認して、viewBoxとSASS（$orgWidth, $orgHeight）に反映（縦横比が重要） ※svgのwidth,heightは無効化する
・SVGを読みやすくする為に・・・
  ・代表的な属性をクラス化
  ・data-nameを全て削除（正規表現「 data-name="[^"]*"」でブランク置換） ※idもほぼ不要だが目印として便利なので残しておく
  ・グループの整理、セクションの識別、空行挿入、など ※座標調整と同時作業でもよい → やりだすとキリがないのでほどほどに
・点数のように桁数が可変の数値は「text-anchor="middle"」が必要
・正誤マークは「text-anchor="middle"」がないと○×が縦に揃わない
・あとは基本的にtranslateの引数やtspan等のx,yを目視で手動調整するしかない
-->
<svg xmlns="http://www.w3.org/2000/svg" _width="592" _height="912.526" viewBox="0 0 538.99 790.99">

  <defs>
    <filter id="パス_249" x="30.8" y="847.316" width="59" height="31.842" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="パス_250" x="30.8" y="880.684" width="59" height="31.842" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-6"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur-6"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>

  <g id="グループ_ALL" transform="translate(-26.5 -120)"><!-- いちばん最初に、オリジナルのSVGにこのグループ（＝階層）を追加して、全体の座標を調整した -->

    <!-- ↓セクション１  -->
    <g id="グループ_1727" transform="translate(7.643 -2.17)">
      <g id="グループ_1707" transform="translate(-13.086 23.118)">
        <path id="パス_50" d="M0,0H487.717" transform="translate(61.585 206.422)" fill="none" stroke="#000" stroke-width="0.5"/>
        <g id="グループ_1697" transform="translate(25 4.053)">

          <rect id="長方形_93" width="103" height="76" rx="12" transform="translate(182.992 119.482)" fill="#f6f6f6"/>
          <path id="パス_203" d="M11.517,0h104.61a11.766,11.766,0,0,1,11.517,12V64a11.766,11.766,0,0,1-11.517,12H11.517A11.766,11.766,0,0,1,0,64V12A11.766,11.766,0,0,1,11.517,0Z" transform="translate(43.869 118.482)" fill="#daf9f3"/>
          <rect id="長方形_94" width="104" height="76" rx="12" transform="translate(297.553 119.482)" fill="#f6f6f6"/>
          <rect id="長方形_95" width="104" height="75" rx="12" transform="translate(411.514 118.482)" fill="#f6f6f6"/>
          <line id="線_8" x2="487.283" y2="0.018" transform="translate(36.586 142.965)" fill="none" stroke="#000" stroke-width="0.5"/>
          <line id="線_9" y1="0.613" x2="487.717" transform="translate(36.586 168.352)" fill="none" stroke="#000" stroke-width="0.5"/>

          <path id="パス_51" d="M4.005,0h94.99A4.134,4.134,0,0,1,103,4.252V16.074a4.134,4.134,0,0,1-4.005,4.252H4.005A4.134,4.134,0,0,1,0,16.074V4.252A4.134,4.134,0,0,1,4.005,0Z" transform="translate(182.992 95)" fill="#28759d"/>
          <path id="パス_52" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(297.553 95)" fill="#28759d"/>
          <path id="パス_53" d="M4.044,0H99.956A4.152,4.152,0,0,1,104,4.252V16.074a4.152,4.152,0,0,1-4.044,4.252H4.044A4.152,4.152,0,0,1,0,16.074V4.252A4.152,4.152,0,0,1,4.044,0Z" transform="translate(411.514 95)" fill="#28759d"/>

          <text id="総合_60点満点_" transform="translate(238.591 109.163)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size10_5_spacing05">
            <tspan x="-44.51">総合（</tspan><tspan font-family="SegoeUI, Segoe UI">60</tspan><tspan>点満点）</tspan>
          </text>
          <text id="検査_30点満点_" transform="translate(352.553 109.163)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size10_5_spacing05">
            <tspan x="-50.023">検査Ⅰ（</tspan><tspan font-family="SegoeUI, Segoe UI">30</tspan><tspan>点満点）</tspan>
          </text>
          <text id="検査_30点満点_2" transform="translate(466.514 109.163)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size10_5_spacing05">
            <tspan x="-50.023">検査Ⅱ（</tspan><tspan font-family="SegoeUI, Segoe UI">30</tspan><tspan>点満点）</tspan>
          </text>

          <text id="得点" transform="translate(96 134.721)" class="MeiryoBold_size10_weight700"><tspan>得点</tspan></text>
          <text id="偏差値_全国_" transform="translate(80.5 160.982)" class="MeiryoBold_size10_weight700"><tspan>偏差値</tspan><tspan font-size="8">（全国）</tspan></text>
          <text id="全国順位" transform="translate(86 186.352)" class="MeiryoBold_size10_weight700"><tspan>全国順位</tspan></text>

          <g id="総合" transform="translate(30.039 -38.518)">
            <text id="_30点" transform="translate(202 175)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][0]['得点'] }}点</tspan></text>
            <text id="_53" transform="translate(201.553 200.5)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][0]['全国偏差値'] }}</tspan></text>
            <text id="_4578位_10000人中" transform="translate(206.553 226)" class="SourceHanSansBold_size9_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][0]['全国順位'] }}位 / 10000人中</tspan></text>
          </g>
          <g id="検査_" transform="translate(145.999 -39.518)">
            <text id="_21点" transform="translate(202 175)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][1]['得点'] }}点</tspan></text>
            <text id="_59" transform="translate(201.553 200.5)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][1]['全国偏差値'] }}</tspan></text>
            <text id="_1821位_10000人中" transform="translate(206.553 226)" class="SourceHanSansBold_size9_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][1]['全国順位'] }}位 / 10000人中</tspan></text>
          </g>
          <g id="検査_2" transform="translate(259.961 -39.018)">
            <text id="_9点" transform="translate(202 175)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][2]['得点'] }}点</tspan></text>
            <text id="_48" transform="translate(201.553 200.5)" class="SourceHanSansBold_size11_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][2]['全国偏差値'] }}</tspan></text>
            <text id="_6541位_10000人中" transform="translate(206.553 226)" class="SourceHanSansBold_size9_weight700_spacing025" text-anchor="middle"><tspan>{{ section1DataSet['columns'][2]['全国順位'] }}位 / 10000人中</tspan></text>
          </g>

        </g>
      </g>
    </g>
    <!-- ↑セクション１  -->

    <!-- ↓セクション２ 左  -->
    <g id="グループ_1731" transform="translate(33.314 -34.728)">
      <g id="グループ_1724" transform="translate(10843.789 6138.728)">

        <rect id="長方形_101" width="228.932" height="172.228" rx="12" transform="translate(-10843.789 -5867.728)" fill="#daf9f3" opacity="0.37"/>
        <path id="パス_55" d="M7.6,0H187.832c4.2,0,7.6,1.9,7.6,4.252V16.074c0,2.348-3.4,4.252-7.6,4.252H7.6c-4.2,0-7.6-1.9-7.6-4.252V4.252C0,1.9,3.4,0,7.6,0Z" transform="translate(-10824.039 -5867.728)" fill="#28759d"/>
        <text id="分野別偏差値チャート" transform="translate(-10725 -5853)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size11_spacing05"><tspan x="-50.694">分野別偏差値チャート</tspan></text>

        <g id="グループ_1723" transform="translate(-10806.934 -5842.607)">

          <text id="文章理解" transform="translate(63.609 7)" font-size="7" font-family="Meiryo"><tspan>文章理解</tspan></text>
          <text id="論理-2" transform="translate(0 73.78)" font-size="7" font-family="Meiryo"><tspan>論理</tspan></text>
          <text id="語い-2" transform="translate(141.22 73.78)" font-size="7" font-family="Meiryo"><tspan>語い</tspan></text>
          <text id="数的能力-2" transform="translate(63.609 142.107)" font-size="7" font-family="Meiryo"><tspan>数的能力</tspan></text>

          <g id="グループ_1722" transform="translate(17.437 10)">

            <g id="パス_212" transform="translate(60.104 0) rotate(45)" fill="rgba(255,255,255,0)">
              <path d="M 86.86808013916016 86.86808013916016 L 1.30757999420166 85.81363677978516 L 0.2531321346759796 0.2531321346759796 L 85.81363677978516 1.30757999420166 L 86.86808013916016 86.86808013916016 Z" stroke="none"/>
              <path d="M 0.5062789916992188 0.5062789916992188 L 1.5545654296875 85.566650390625 L 86.61493682861328 86.61493682861328 L 85.566650390625 1.5545654296875 L 0.5062789916992188 0.5062789916992188 M 0 0 L 86.06060791015625 1.06060791015625 L 87.1212158203125 87.1212158203125 L 1.06060791015625 86.06060791015625 L 0 0 Z" stroke="none" fill="#000"/>
            </g>
            <g id="パス_213" transform="translate(60.104 7.071) rotate(45)" fill="rgba(255,255,255,0)">
              <path d="M 76.86775970458984 76.86775970458984 L 1.307251334190369 75.81407165527344 L 0.2535659372806549 0.2535659372806549 L 75.81407165527344 1.307251334190369 L 76.86775970458984 76.86775970458984 Z" stroke="none"/>
              <path d="M 0.5071182250976562 0.5071182250976562 L 1.5538330078125 75.56748962402344 L 76.61420440673828 76.61420440673828 L 75.56748962402344 1.5538330078125 L 0.5071182250976562 0.5071182250976562 M 0 0 L 76.06066131591797 1.060661315917969 L 77.12132263183594 77.12132263183594 L 1.060661315917969 76.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
            </g>
            <g id="パス_214" transform="translate(60.104 14.142) rotate(45)" fill="rgba(255,255,255,0)">
              <path d="M 66.86721038818359 66.86721038818359 L 1.306744575500488 65.8145751953125 L 0.2541157305240631 0.2541157305240631 L 65.8145751953125 1.306744575500488 L 66.86721038818359 66.86721038818359 Z" stroke="none"/>
              <path d="M 0.5082321166992188 0.5082321166992188 L 1.552825927734375 65.56849670410156 L 66.61309051513672 66.61309051513672 L 65.56849670410156 1.552825927734375 L 0.5082321166992188 0.5082321166992188 M 0 0 L 66.06066131591797 1.060661315917969 L 67.12132263183594 67.12132263183594 L 1.060661315917969 66.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
            </g>
            <g id="パス_215" transform="translate(60.104 21.213) rotate(45)" fill="rgba(255,255,255,0)">
              <path d="M 56.86645126342773 56.86645126342773 L 1.306067705154419 55.81525421142578 L 0.2548729479312897 0.2548729479312897 L 55.81525421142578 1.306067705154419 L 56.86645126342773 56.86645126342773 Z" stroke="none"/>
              <path d="M 0.509735107421875 0.509735107421875 L 1.551464080810547 55.56985855102539 L 56.61158752441406 56.61158752441406 L 55.56985855102539 1.551464080810547 L 0.509735107421875 0.509735107421875 M 0 0 L 56.06066131591797 1.060661315917969 L 57.12132263183594 57.12132263183594 L 1.060661315917969 56.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
            </g>
            <g id="パス_216" transform="translate(60.104 28.284) rotate(45)" fill="rgba(255,255,255,0)">
              <path d="M 46.86536407470703 46.86536407470703 L 1.30509340763092 45.81622695922852 L 0.2559560835361481 0.2559560835361481 L 45.81622695922852 1.30509340763092 L 46.86536407470703 46.86536407470703 Z" stroke="none"/>
              <path d="M 0.5119209289550781 0.5119209289550781 L 1.549537658691406 45.57178115844727 L 46.60939788818359 46.60939788818359 L 45.57178115844727 1.549537658691406 L 0.5119209289550781 0.5119209289550781 M 0 0 L 46.06066131591797 1.060657501220703 L 47.12131881713867 47.12131881713867 L 1.060657501220703 46.06066131591797 L 0 0 Z" stroke="none" fill="#000"/>
            </g>
            <g id="長方形_419" transform="translate(60.104 36.855) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
              <rect width="35" height="35" stroke="none"/>
              <rect x="0.25" y="0.25" width="34.5" height="34.5" fill="none"/>
            </g>
            <text id="_80_70_60_50" transform="translate(54.916 7.776)" font-size="4.592" font-family="SegoeUI, Segoe UI">
              <tspan x="-2.475" y="0">80</tspan>
              <tspan x="-2.475" y="6.061">70</tspan>
              <tspan x="-2.475" y="12.123">60</tspan>
              <tspan x="-2.475" y="18.184">50</tspan>
            </text>
            <text id="_40_30_20" transform="translate(54.916 40.015)" font-size="4.592" font-family="SegoeUI, Segoe UI">
              <tspan x="-2.475" y="0">40</tspan>
              <tspan x="-2.475" y="5.189">30</tspan>
              <tspan x="-2.475" y="10.378">20</tspan>
            </text>
            <line id="線_555" x2="120" transform="translate(0.347 61.751)" fill="none" stroke="#000" stroke-width="0.5"/>
            <line id="線_556" y2="122" transform="translate(60.104 0.748)" fill="none" stroke="#000" stroke-width="0.5"/>
            <g id="長方形_420" transform="translate(60.104 43.864) rotate(45)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="0.5">
              <rect width="25" height="25" stroke="none"/>
              <rect x="0.25" y="0.25" width="24.5" height="24.5" fill="none"/>
            </g>

            <!-- レーダーチャート -->
            <!--
            <path id="_調整用_80" data-name="_調整用_80" :d="`M 0,-${calcRadarChartLen(80)} L ${calcRadarChartLen(80)},0 0,${calcRadarChartLen(80)} -${calcRadarChartLen(80)},0 0,-${calcRadarChartLen(80)}    0,${calcRadarChartLen(80)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
            <path id="_調整用_50" data-name="_調整用_50" :d="`M 0,-${calcRadarChartLen(50)} L ${calcRadarChartLen(50)},0 0,${calcRadarChartLen(50)} -${calcRadarChartLen(50)},0 0,-${calcRadarChartLen(50)}    0,0 ${calcRadarChartLen(50)},0`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>
            <path id="_調整用_20" data-name="_調整用_20" :d="`M 0,-${calcRadarChartLen(20)} L ${calcRadarChartLen(20)},0 0,${calcRadarChartLen(20)} -${calcRadarChartLen(20)},0 0,-${calcRadarChartLen(20)}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#00ff00" :stroke-width="`${radar_chart.line_width}`"/>
            -->
            <path id="パス_218" data-name="パス_218" :d="`M 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])} L ${calcRadarChartLen(section2DataSet['rows'][0]['rows'][1]['全国偏差値_adj_20_80'])},0 0,${calcRadarChartLen(section2DataSet['rows'][1]['rows'][0]['全国偏差値_adj_20_80'])} -${calcRadarChartLen(section2DataSet['rows'][1]['rows'][1]['全国偏差値_adj_20_80'])},0 0,-${calcRadarChartLen(section2DataSet['rows'][0]['rows'][0]['全国偏差値_adj_20_80'])}`" :transform="`translate(${radar_chart.center_x} ${radar_chart.center_y})`" fill="none" stroke="#c70707" :stroke-width="`${radar_chart.line_width}`"/>

          </g>

        </g>

      </g>
    </g>
    <!-- ↑セクション２ 左  -->

    <!-- ↓セクション２ 右  -->
    <g id="グループ_1726" transform="translate(10019.712 6181.614)">
      <g id="グループ_1730" transform="translate(14 -8)">
        <g id="分野別結果表" transform="translate(-9761.272 -5916.36)">

          <rect id="長方形_107" width="276.061" height="21" transform="translate(0 114.721)" fill="#daf9f3"/>
          <path id="パス_60" d="M0,0H276.061V21H42.25v92.548H0V0Z" transform="translate(0 0.107)" fill="#daf9f3"/>
          <line id="線_77" x2="276.061" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_77-2" x2="276.061" transform="translate(0 21)" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_77-3" x2="233.288" y2="0.055" transform="translate(42.772 45.141)" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_77-4" x2="276.061" transform="translate(0 67.793)" fill="none" stroke="#28759d" stroke-width="2"/>
          <path id="パス_211" d="M-20.11,0H213" transform="translate(63.061 90.391)" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_77-5" x2="276.061" transform="translate(0 114.596)" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_77-6" x2="276.061" transform="translate(0 135.596)" fill="none" stroke="#28759d" stroke-width="2"/>
          <line id="線_78" y2="135.596" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
          <line id="線_78-2" x1="0.228" y2="114.141" transform="translate(42.772)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
          <line id="線_78-3" y2="135.596" transform="translate(138.03)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
          <line id="線_78-4" y2="135.596" transform="translate(207.045)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>
          <line id="線_78-5" y2="135.596" transform="translate(276.061)" fill="none" stroke="#28759d" stroke-linecap="round" stroke-width="2"/>

          <text id="_8_12_7_3_30" transform="translate(171.984 38.5)" class="SourceHanSansBold_size11_weight700_spacing025">
            <tspan text-anchor="middle" x="0" y="0"   >{{ section2DataSet['rows'][0]['rows'][0]['得点'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="22.6">{{ section2DataSet['rows'][0]['rows'][1]['得点'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="45.2">{{ section2DataSet['rows'][1]['rows'][0]['得点'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="67.8">{{ section2DataSet['rows'][1]['rows'][1]['得点'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="90.4">{{ section2DataSet['rows'][2]['rows'][0]['得点'] }}</tspan>
          </text>
          <text id="_64_55_52_43_54" transform="translate(242.108 38.5)" class="SourceHanSansBold_size11_weight700_spacing025">
            <tspan text-anchor="middle" x="0" y="0"   >{{ section2DataSet['rows'][0]['rows'][0]['全国偏差値'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="22.6">{{ section2DataSet['rows'][0]['rows'][1]['全国偏差値'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="45.2">{{ section2DataSet['rows'][1]['rows'][0]['全国偏差値'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="67.8">{{ section2DataSet['rows'][1]['rows'][1]['全国偏差値'] }}</tspan>
            <tspan text-anchor="middle" x="0" y="90.4">{{ section2DataSet['rows'][2]['rows'][0]['全国偏差値'] }}</tspan>
          </text>

          <text id="分野" transform="translate(88.527 13.5)" class="SourceHanSansBold_size9_weight700"><tspan x="-9">分野</tspan></text>
          <text id="得点-2" transform="translate(171.984 13.5)" class="SourceHanSansBold_size9_weight700"><tspan x="-9">得点</tspan></text>
          <text id="偏差値" transform="translate(241.608 13.5)" class="SourceHanSansBold_size9_weight700"><tspan x="-13.5">偏差値</tspan></text>
          <text id="検査_3" transform="translate(22.5 48.511)" class="SourceHanSansBold_size9_weight700"><tspan x="-13.5">検査Ⅰ</tspan></text>
          <text id="検査_4" transform="translate(22.5 97.074)" class="SourceHanSansBold_size9_weight700"><tspan x="-13.5">検査Ⅱ</tspan></text>
          <text id="文章読解" transform="translate(88.527 38.5)" class="SourceHanSansBold_size9_weight700_spacing05"><tspan x="-18.675">文章読解</tspan></text>
          <text id="語い" transform="translate(88.527 38.5)" class="SourceHanSansBold_size9_weight700_spacing05"><tspan x="-9.225" y="22.6">語い</tspan></text>
          <text id="数的能力" transform="translate(88.527 38.5)" class="SourceHanSansBold_size9_weight700_spacing05"><tspan x="-18.675" y="45.2">数的能力</tspan></text>
          <text id="論理" transform="translate(88.527 38.5)" class="SourceHanSansBold_size9_weight700_spacing05"><tspan x="-9.225" y="67.8">論理</tspan></text>
          <text id="総_合_計" transform="translate(65.354 129.096)" class="SourceHanSansBold_size9_weight700"><tspan x="-19.629" xml:space="preserve">総   合   計</tspan></text>
        </g>

      </g>
    </g>
    <!-- ↑セクション２ 右  -->

    <!-- ↓セクション３－１ -->
    <g id="グループ_1814" transform="translate(0 -6.582)">

      <!-- ↓セクション３－１ 左 -->
      <g id="グループ_1788" transform="translate(8 4.894)">

        <g id="グループ_1787">

          <rect id="長方形_423" width="210" height="15" transform="translate(83 436)" fill="#f3f3f3"/>
          <g id="グループ_1763" transform="translate(9917 6010)">

            <g id="グループ_1740" transform="translate(2 15)">
              <g id="グループ_1739" transform="translate(167 189)">
                <path id="パス_222" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
                <path id="パス_222-2" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
                <path id="パス_222-3" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
                <path id="パス_222-4" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
                <path id="パス_222-5" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
                <path id="パス_222-6" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
                <path id="パス_222-7" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
                <path id="パス_222-8" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
                <path id="パス_222-9" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
                <path id="パス_222-10" d="M0,0H209V13H0Z" transform="translate(-10003 -5655)" fill="#f3f3f3"/>
                <path id="パス_222-11" d="M0,0H209V13H0Z" transform="translate(-10003 -5643)" fill="#fff"/>
                <path id="パス_222-12" d="M0,0H209V13H0Z" transform="translate(-10003 -5631)" fill="#f3f3f3"/>
                <path id="パス_222-13" d="M0,0H209V13H0Z" transform="translate(-10003 -5619)" fill="#fff"/>
                <path id="パス_222-14" d="M0,0H209V13H0Z" transform="translate(-10003 -5607)" fill="#f3f3f3"/>
                <path id="パス_223" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
              </g>
            </g><!-- グループ_1740 -->

            <g>
              <path id="パス_226" d="M0,0H209" transform="translate(-9834 -5546.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_227" d="M0,0H209" transform="translate(-9834 -5534.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_240" d="M0,0H209" transform="translate(-9834 -5558.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_228" d="M0,0H209" transform="translate(-9834 -5522.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_229" d="M0,0H209" transform="translate(-9834 -5510.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_230" d="M0,0H209" transform="translate(-9834 -5498.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_231" d="M0,0H209" transform="translate(-9834 -5486.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_232" d="M0,0H209" transform="translate(-9834 -5474.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_233" d="M0,0H209" transform="translate(-9834 -5462.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_234" d="M0,0H209" transform="translate(-9834 -5450.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_235" d="M0,0H209" transform="translate(-9834 -5438.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_236" d="M0,0H209" transform="translate(-9834 -5426.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_237" d="M0,0H209" transform="translate(-9834 -5414.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_238" d="M0,0H209" transform="translate(-9834 -5402.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
              <path id="パス_239" d="M0,0H209" transform="translate(-9834 -5390.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            </g>

            <g id="グループ_1742" transform="translate(-2 16)">
              <rect id="長方形_80" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_2-2" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">2</tspan></text>
            </g>
            <g id="グループ_1764" transform="translate(-2 4.107)">
              <rect id="長方形_80-2" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_1" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">1</tspan></text>
            </g>
            <g id="グループ_1743" transform="translate(-2 28)">
              <rect id="長方形_80-3" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">3</tspan></text>
            </g>
            <g id="グループ_1744" transform="translate(-2 40)">
              <rect id="長方形_80-4" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_4" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">4</tspan></text>
            </g>
            <g id="グループ_1745" transform="translate(-2 52)">
              <rect id="長方形_80-5" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_5" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">5</tspan></text>
            </g>
            <g id="グループ_1746" transform="translate(-2 64)">
              <rect id="長方形_80-6" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_6" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">6</tspan></text>
            </g>
            <g id="グループ_1747" transform="translate(-2 76)">
              <rect id="長方形_80-7" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_7" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">7</tspan></text>
            </g>
            <g id="グループ_1748" transform="translate(-2 88)">
              <rect id="長方形_80-8" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_8" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">8</tspan></text>
            </g>
            <g id="グループ_1749" transform="translate(-2 100)">
              <rect id="長方形_80-9" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_9" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">9</tspan></text>
            </g>
            <g id="グループ_1756" transform="translate(-2 124)">
              <rect id="長方形_80-10" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_11" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">11</tspan></text>
            </g>
            <g id="グループ_1761" transform="translate(-2 112)">
              <rect id="長方形_80-11" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_10" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">10</tspan></text>
            </g>
            <g id="グループ_1757" transform="translate(-2 136)">
              <rect id="長方形_80-12" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_12" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">12</tspan></text>
            </g>
            <g id="グループ_1758" transform="translate(-2 148)">
              <rect id="長方形_80-13" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_13" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">13</tspan></text>
            </g>
            <g id="グループ_1759" transform="translate(-2 160)">
              <rect id="長方形_80-14" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_14" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">14</tspan></text>
            </g>
            <g id="グループ_1760" transform="translate(-2 172)">
              <rect id="長方形_80-15" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
              <text id="_15" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">15</tspan></text>
            </g>

          </g><!-- グループ_1763 -->

          <text id="熟語_熟語_熟語_熟語_熟語_熟語_熟語_多義語_多義語_多義語_文章読解_接続_文章読解_指示語_文章読解_空欄補充_文章読解_内容一致_文章整序_"
                transform="translate(109.144 448.107)" class="Meiryo_size8_33_spacing05">
            <tspan x="0" y="0">熟語</tspan>
            <tspan x="0" y="12">熟語</tspan>
            <tspan x="0" y="24">熟語</tspan>
            <tspan x="0" y="36">熟語</tspan>
            <tspan x="0" y="48">熟語</tspan>
            <tspan x="0" y="60">熟語</tspan>
            <tspan x="0" y="72">熟語</tspan>
            <tspan x="0" y="84">多義語</tspan>
            <tspan x="0" y="96">多義語</tspan>
            <tspan x="0" y="108">多義語</tspan>
            <tspan x="0" y="120">文章読解（接続）</tspan>
            <tspan x="0" y="132">文章読解（指示語）</tspan>
            <tspan x="0" y="144">文章読解（空欄補充）</tspan>
            <tspan x="0" y="156">文章読解（内容一致）</tspan>
            <tspan x="0" y="168">文章整序</tspan>
            <tspan x="0" y="180"></tspan>
          </text>

          <line id="線_557" x2="209" transform="translate(83.5 436.5)" fill="none" stroke="#000" stroke-width="1"/>

        </g><!-- グループ_1787 -->

        <g transform="translate(80 433)">
          <text id="番号"     transform="translate(8 0)"   class="YuGothicUI_size7_1_spacing05"><tspan>番号</tspan></text>
          <text id="出題項目" transform="translate(32 0)"  class="YuGothicUI_size7_1_spacing05"><tspan>出題項目</tspan></text>
          <text id="配点"     transform="translate(140 0)" class="YuGothicUI_size7_1_spacing05"><tspan>配点</tspan></text>
          <text id="正誤"     transform="translate(167 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正誤</tspan></text>
          <text id="正答率"   transform="translate(190 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正答率</tspan></text>
        </g>

        <g id="グループ_1786" transform="translate(16.5)">
          <text id="_10-2" transform="translate(257 608)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][14]['正答率'] }}</tspan></text>
          <text id="_22"   transform="translate(257 596)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][13]['正答率'] }}</tspan></text>
          <text id="_77"   transform="translate(257 584)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][12]['正答率'] }}</tspan></text>
          <text id="_36"   transform="translate(257 572)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][11]['正答率'] }}</tspan></text>
          <text id="_44"   transform="translate(257 560)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][10]['正答率'] }}</tspan></text>
          <text id="_54"   transform="translate(257 548)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][9]['正答率'] }}</tspan></text>
          <text id="_72"   transform="translate(257 536)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][8]['正答率'] }}</tspan></text>
          <text id="_33"   transform="translate(257 524)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][7]['正答率'] }}</tspan></text>
          <text id="_13-2" transform="translate(257 512)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][6]['正答率'] }}</tspan></text>
          <text id="_92"   transform="translate(257 500)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][5]['正答率'] }}</tspan></text>
          <text id="_45"   transform="translate(257 488)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][4]['正答率'] }}</tspan></text>
          <text id="_83"   transform="translate(257 476)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][3]['正答率'] }}</tspan></text>
          <text id="_22-2" transform="translate(257 464)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][2]['正答率'] }}</tspan></text>
          <text id="_41"   transform="translate(257 452)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][1]['正答率'] }}</tspan></text>
          <text id="_56"   transform="translate(257 440)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][0]['正答率'] }}</tspan></text>
        </g>

        <g id="グループ_1785" transform="translate(17)">
          <text id="〇"    transform="translate(231 608)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
          <text id="〇-2"  transform="translate(231 596)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
          <text id="_"     transform="translate(231 584)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
          <text id="〇-3"  transform="translate(231 572)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
          <text id="〇-4"  transform="translate(231 560)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
          <text id="_2-3"  transform="translate(231 548)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
          <text id="_3-2"  transform="translate(231 536)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
          <text id="_4-2"  transform="translate(231 524)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
          <text id="〇-5"  transform="translate(231 512)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
          <text id="〇-6"  transform="translate(231 500)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
          <text id="〇-7"  transform="translate(231 488)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
          <text id="〇-8"  transform="translate(231 476)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
          <text id="〇-9"  transform="translate(231 464)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
          <text id="_5-2"  transform="translate(231 452)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
          <text id="〇-10" transform="translate(231 440)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section31DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
        </g>

        <g id="グループ_1784" transform="translate(15)">
          <text id="_1-2"  transform="translate(210 608)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-3"  transform="translate(210 596)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-4"  transform="translate(210 584)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-5"  transform="translate(210 572)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-6"  transform="translate(210 560)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-7"  transform="translate(210 548)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-8"  transform="translate(210 536)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-9"  transform="translate(210 524)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-10" transform="translate(210 512)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-11" transform="translate(210 500)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-12" transform="translate(210 488)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-13" transform="translate(210 476)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-14" transform="translate(210 464)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-15" transform="translate(210 452)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-16" transform="translate(210 440)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
        </g>

        <g id="グループ_1783" transform="translate(34 0.5)">
          <g id="コンポーネント_3_1" transform="translate(173 609.188)">
            <rect id="長方形_119" width="9" height="9" fill="#209115"/>
            <text id="文-5" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
          </g>
          <g id="グループ_1766" transform="translate(9785 6002)">
            <rect id="長方形_422" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
            <text id="文" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">文</tspan></text>
          </g>
          <g id="グループ_1770" transform="translate(9785 5990)">
            <rect id="長方形_422-2" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
            <text id="文-2" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">文</tspan></text>
          </g>
          <g id="グループ_1771" transform="translate(9785 5978)">
            <rect id="長方形_422-3" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
            <text id="文-3" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">文</tspan></text>
          </g>
          <g id="グループ_1772" transform="translate(9785 5966)">
            <rect id="長方形_422-4" width="9" height="9" transform="translate(-9612 -5405)" fill="#209115"/>
            <text id="文-4" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">文</tspan></text>
          </g>
          <g id="グループ_1774" transform="translate(9785 5942)">
            <rect id="長方形_422-5" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1922" transform="translate(9785 5954)">
            <rect id="長方形_422-6" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-2" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1775" transform="translate(9785 5930)">
            <rect id="長方形_422-7" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-3" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1776" transform="translate(9785 5918)">
            <rect id="長方形_422-8" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-4" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1777" transform="translate(9785 5906)">
            <rect id="長方形_422-9" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-5" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1778" transform="translate(9785 5894)">
            <rect id="長方形_422-10" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-6" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1779" transform="translate(9785 5882)">
            <rect id="長方形_422-11" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-7" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1780" transform="translate(9785 5870)">
            <rect id="長方形_422-12" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-8" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1781" transform="translate(9785 5858)">
            <rect id="長方形_422-13" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-9" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
          <g id="グループ_1782" transform="translate(9785 5846)">
            <rect id="長方形_422-14" width="9" height="9" transform="translate(-9612 -5405)" fill="#1c4817"/>
            <text id="語-10" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">語</tspan></text>
          </g>
        </g><!-- グループ_1783 -->

      </g><!-- グループ_1788 -->
      <!-- ↑セクション３－１ 左 -->

      <!-- ↓セクション３－１ 右 -->
      <g id="グループ_1801" transform="translate(-4 7)">
        <g id="グループ_1791" transform="translate(9425 6050.553)">

          <rect id="長方形_423-2" width="210" height="15" transform="translate(-9095 -5616.553)" fill="#f3f3f3"/>
          <g id="グループ_1740-2" transform="translate(741 -27.553)">
            <text id="_2-4" transform="translate(-9808 -5454)" class="Meiryo_size7_spacing05"><tspan y="7">2</tspan></text>
            <g id="グループ_1739-2" transform="translate(167 189)">
              <path id="パス_222-15" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
              <path id="パス_222-16" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
              <path id="パス_222-17" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
              <path id="パス_222-18" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
              <path id="パス_222-19" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
              <path id="パス_222-20" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
              <path id="パス_222-21" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
              <path id="パス_222-22" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
              <path id="パス_222-23" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
              <path id="パス_222-24" d="M0,0H209V13H0Z" transform="translate(-10003 -5655)" fill="#f3f3f3"/>
              <path id="パス_222-25" d="M0,0H209V13H0Z" transform="translate(-10003 -5643)" fill="#fff"/>
              <path id="パス_222-26" d="M0,0H209V13H0Z" transform="translate(-10003 -5631)" fill="#f3f3f3"/>
              <path id="パス_222-27" d="M0,0H209V13H0Z" transform="translate(-10003 -5619)" fill="#fff"/>
              <path id="パス_222-28" d="M0,0H209V13H0Z" transform="translate(-10003 -5607)" fill="#f3f3f3"/>
              <path id="パス_223-2" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            </g>
          </g>

          <g>
            <path id="パス_226-2" d="M0,0H209" transform="translate(-9095 -5589.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_227-2" d="M0,0H209" transform="translate(-9095 -5577.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_240-2" d="M0,0H209" transform="translate(-9095 -5601.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_228-2" d="M0,0H209" transform="translate(-9095 -5565.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_229-2" d="M0,0H209" transform="translate(-9095 -5553.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_230-2" d="M0,0H209" transform="translate(-9095 -5541.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_231-2" d="M0,0H209" transform="translate(-9095 -5529.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_232-2" d="M0,0H209" transform="translate(-9095 -5517.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_233-2" d="M0,0H209" transform="translate(-9095 -5505.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_234-2" d="M0,0H209" transform="translate(-9095 -5493.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_235-2" d="M0,0H209" transform="translate(-9095 -5481.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_236-2" d="M0,0H209" transform="translate(-9095 -5469.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_237-2" d="M0,0H209" transform="translate(-9095 -5457.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_238-2" d="M0,0H209" transform="translate(-9095 -5445.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_239-2" d="M0,0H209" transform="translate(-9095 -5433.053)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          </g>

          <g id="グループ_1756-2" transform="translate(737 81.447)">
            <rect id="長方形_80-16" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_26" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">26</tspan></text>
          </g>
          <g id="グループ_1761-2" transform="translate(737 69.447)">
            <rect id="長方形_80-17" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_25" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">25</tspan></text>
          </g>
          <g id="グループ_1792" transform="translate(737 57.447)">
            <rect id="長方形_80-18" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_24" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">24</tspan></text>
          </g>
          <g id="グループ_1793" transform="translate(737 45.447)">
            <rect id="長方形_80-19" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_23" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">23</tspan></text>
          </g>
          <g id="グループ_1794" transform="translate(737 33.447)">
            <rect id="長方形_80-20" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_22-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">22</tspan></text>
          </g>
          <g id="グループ_1795" transform="translate(737 22.054)">
            <rect id="長方形_80-21" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_21" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">21</tspan></text>
          </g>
          <g id="グループ_1796" transform="translate(737 9.447)">
            <rect id="長方形_80-22" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_20" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">20</tspan></text>
          </g>
          <g id="グループ_1797" transform="translate(737 -2.553)">
            <rect id="長方形_80-23" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_19" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">19</tspan></text>
          </g>
          <g id="グループ_1798" transform="translate(737 -14.553)">
            <rect id="長方形_80-24" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_18" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">18</tspan></text>
          </g>
          <g id="グループ_1799" transform="translate(737 -26.053)">
            <rect id="長方形_80-25" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_17" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">17</tspan></text>
          </g>
          <g id="グループ_1800" transform="translate(737 -38.553)">
            <rect id="長方形_80-26" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_16" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">16</tspan></text>
          </g>
          <g id="グループ_1757-2" transform="translate(737 93.447)">
            <rect id="長方形_80-27" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_27" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">27</tspan></text>
          </g>
          <g id="グループ_1758-2" transform="translate(737 105.447)">
            <rect id="長方形_80-28" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_28" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">28</tspan></text>
          </g>
          <g id="グループ_1759-2" transform="translate(737 117.447)">
            <rect id="長方形_80-29" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_29" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">29</tspan></text>
          </g>
          <g id="グループ_1760-2" transform="translate(737 129.447)">
            <rect id="長方形_80-30" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_30" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">30</tspan></text>
          </g>
          <line id="線_557-2" x2="209" transform="translate(-9094.5 -5616.053)" fill="none" stroke="#000" stroke-width="1"/>

          <g transform="translate(-9100 -5619)">
            <text id="番号"     transform="translate(8 0)"   class="YuGothicUI_size7_1_spacing05"><tspan>番号</tspan></text>
            <text id="出題項目" transform="translate(33 0)"  class="YuGothicUI_size7_1_spacing05"><tspan>出題項目</tspan></text>
            <text id="配点"     transform="translate(146 0)" class="YuGothicUI_size7_1_spacing05"><tspan>配点</tspan></text>
            <text id="正誤"     transform="translate(169 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正誤</tspan></text>
            <text id="正答率"   transform="translate(190 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正答率</tspan></text>
          </g>

          <text id="_33-2" transform="translate(-8905.029 -5444.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][29]['正答率'] }}</tspan></text>
          <text id="_88"   transform="translate(-8905.029 -5456.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][28]['正答率'] }}</tspan></text>
          <text id="_33-3" transform="translate(-8905.029 -5468.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][27]['正答率'] }}</tspan></text>
          <text id="_21-2" transform="translate(-8905.029 -5480.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][26]['正答率'] }}</tspan></text>
          <text id="_40"   transform="translate(-8905.029 -5492.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][25]['正答率'] }}</tspan></text>
          <text id="_49"   transform="translate(-8905.029 -5504.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][24]['正答率'] }}</tspan></text>
          <text id="_50"   transform="translate(-8905.029 -5516.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][23]['正答率'] }}</tspan></text>
          <text id="_72-2" transform="translate(-8905.029 -5528.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][22]['正答率'] }}</tspan></text>
          <text id="_33-4" transform="translate(-8905.029 -5540.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][21]['正答率'] }}</tspan></text>
          <text id="_13-3" transform="translate(-8905.029 -5552.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][20]['正答率'] }}</tspan></text>
          <text id="_92-2" transform="translate(-8905.029 -5564.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][19]['正答率'] }}</tspan></text>
          <text id="_45-2" transform="translate(-8905.029 -5576.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][18]['正答率'] }}</tspan></text>
          <text id="_83-2" transform="translate(-8905.029 -5588.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][17]['正答率'] }}</tspan></text>
          <text id="_82"   transform="translate(-8905.029 -5600.553)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][16]['正答率'] }}</tspan></text>
          <text id="_34"   transform="translate(-8905.029 -5613.053)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section31DataSet['rows'][15]['正答率'] }}</tspan></text>

          <text id="N"     transform="translate(-8932 -5444.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][29]['正誤マーク'] }}</tspan></text>
          <text id="N-2"   transform="translate(-8932 -5456.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][28]['正誤マーク'] }}</tspan></text>
          <text id="N-3"   transform="translate(-8932 -5468.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][27]['正誤マーク'] }}</tspan></text>
          <text id="〇-11" transform="translate(-8932 -5480.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][26]['正誤マーク'] }}</tspan></text>
          <text id="N-4"   transform="translate(-8932 -5492.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][25]['正誤マーク'] }}</tspan></text>
          <text id="〇-12" transform="translate(-8932 -5504.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][24]['正誤マーク'] }}</tspan></text>
          <text id="〇-13" transform="translate(-8932 -5516.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][23]['正誤マーク'] }}</tspan></text>
          <text id="_6-2"  transform="translate(-8932 -5528.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][22]['正誤マーク'] }}</tspan></text>
          <text id="〇-14" transform="translate(-8932 -5540.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][21]['正誤マーク'] }}</tspan></text>
          <text id="〇-15" transform="translate(-8932 -5552.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][20]['正誤マーク'] }}</tspan></text>
          <text id="_7-2"  transform="translate(-8932 -5564.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
          <text id="〇-16" transform="translate(-8932 -5576.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
          <text id="〇-17" transform="translate(-8932 -5588.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
          <text id="〇-18" transform="translate(-8932 -5600.553)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
          <text id="_8-2"  transform="translate(-8932 -5613.053)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="8.0" y="8">{{ section31DataSet['rows'][15]['正誤マーク'] }}</tspan></text>

          <text id="_1-17" transform="translate(-8952 -5443.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-18" transform="translate(-8952 -5455.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-19" transform="translate(-8952 -5469.357)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-20" transform="translate(-8952 -5479.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-21" transform="translate(-8952 -5491.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-22" transform="translate(-8952 -5505.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-23" transform="translate(-8952 -5515.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-24" transform="translate(-8952 -5527.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-25" transform="translate(-8952 -5541.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-26" transform="translate(-8952 -5553.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-27" transform="translate(-8952 -5563.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-28" transform="translate(-8952 -5575.553)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-29" transform="translate(-8952 -5589.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-30" transform="translate(-8952 -5601.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>
          <text id="_1-31" transform="translate(-8952 -5613.053)" class="SegoeUI_size7_1_spacing05"><tspan x="3.5" y="8">1</tspan></text>

          <text id="文章整序_空欄補充_空欄補充_二語の関係_反対語_二語の関係_役割_二語の関係_同意語_二語の関係_同列_二語の関係_反対語_二語の関係_大小_二語の関係_同意語_文の完成_文の完成_文の完成_文法_文法_"
                transform="translate(-9068.71 -5603.447)" class="Meiryo_size8_33_spacing05">
            <tspan x="0" y="0">文章整序</tspan>
            <tspan x="0" y="12">空欄補充</tspan>
            <tspan x="0" y="24">空欄補充</tspan>
            <tspan x="0" y="36">二語の関係（反対語）</tspan>
            <tspan x="0" y="48">二語の関係（役割）</tspan>
            <tspan x="0" y="60">二語の関係（同意語）</tspan>
            <tspan x="0" y="72">二語の関係（同列）</tspan>
            <tspan x="0" y="84">二語の関係（反対語）</tspan>
            <tspan x="0" y="96">二語の関係（大小）</tspan>
            <tspan x="0" y="108">二語の関係（同意語）</tspan>
            <tspan x="0" y="120">３文の完成</tspan>
            <tspan x="0" y="132">３文の完成</tspan>
            <tspan x="0" y="144">３文の完成</tspan>
            <tspan x="0" y="156">文法</tspan>
            <tspan x="0" y="168">文法</tspan>
            <tspan x="0" y="180"></tspan>
          </text>

          <g id="コンポーネント_4_1" transform="translate(-8968 -5574.865)">
            <rect id="長方形_119-2" width="9" height="8" fill="#1c4817"/>
            <text id="語-11" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_2" transform="translate(-8968 -5562.865)">
            <rect id="長方形_119-3" width="9" height="8" fill="#1c4817"/>
            <text id="語-12" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_3" transform="translate(-8968 -5550.865)">
            <rect id="長方形_119-4" width="9" height="8" fill="#1c4817"/>
            <text id="語-13" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_4" transform="translate(-8968 -5538.865)">
            <rect id="長方形_119-5" width="9" height="8" fill="#1c4817"/>
            <text id="語-14" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_5" transform="translate(-8968 -5526.865)">
            <rect id="長方形_119-6" width="9" height="8" fill="#1c4817"/>
            <text id="語-15" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_6" transform="translate(-8968 -5513.865)">
            <rect id="長方形_119-7" width="9" height="8" fill="#1c4817"/>
            <text id="語-16" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>
          <g id="コンポーネント_4_7" transform="translate(-8968 -5502.865)">
            <rect id="長方形_119-8" width="9" height="8" fill="#1c4817"/>
            <text id="語-17" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
          </g>

        </g><!-- グループ_1791 -->
      </g><!-- グループ_1801 -->
      <!-- ↑セクション３－１ 右 -->

      <g id="グループ_1762" transform="translate(9822.712 6364.238)">

        <g id="長方形_102" transform="translate(-9796.212 -5940.525)" fill="none" stroke="#28759d" stroke-width="2">
          <rect width="539" height="207" rx="14" stroke="none"/>
          <rect x="1" y="1" width="537" height="205" rx="13" fill="none"/>
        </g>

        <g id="グループ_1682" transform="translate(865.97 -163.561)">
          <path id="パス_17" d="M72.345,475.865c0,59.923,9.333,102.765,9.333,102.765H54.454c-3.384-.517-9.944-6.256-9.944-9.238V381.924c0-2.982,7.032-10.294,10.151-10.294l27.017,1.009S72.345,415.942,72.345,475.865Z" transform="translate(-10706.691 -6148.595)" fill="#28759d"/>
          <g id="グループ_533" transform="translate(-10655.123 -5707.819)">
            <g id="グループ_532" transform="translate(1 -2)">
              <path id="パス_18" d="M62.745,442.055a4.166,4.166,0,0,0,2.746,1.858,3.069,3.069,0,0,0-.815,1.07,5,5,0,0,1-2.866-2.129,5.091,5.091,0,0,1-3.227,2.14,4.971,4.971,0,0,0-.755-.952c1.835-.518,2.639-1.22,2.974-1.987H58.607v-2.982H61.03v-.692h-1.5v-.55a7.817,7.817,0,0,1-.984.529,4.417,4.417,0,0,0-.348-.626v.518H57.108v.519c.324.378,1.163,1.534,1.343,1.8l-.72.994c-.131-.314-.371-.778-.623-1.232v4.591H55.789v-3.975a8.308,8.308,0,0,1-1.092,2.074,6.1,6.1,0,0,0-.647-1.145,10.109,10.109,0,0,0,1.655-3.63H54.326v-1.188h1.463v-2.215h1.319v2.215H58.2v.075a7.2,7.2,0,0,0,2.783-2.279h1.3a7.516,7.516,0,0,0,3.3,2.366,7.156,7.156,0,0,0-.731,1.123,9.8,9.8,0,0,1-.948-.5v.529H62.313v.692h2.555v2.982Zm-1.727-.983c.012-.119.012-.248.012-.367v-.649H59.843v1.016Zm2.1-3.749a7.631,7.631,0,0,1-1.451-1.362,7.2,7.2,0,0,1-1.392,1.362Zm-.8,2.733v.681c0,.108,0,.226-.011.335h1.283v-1.016Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
              <path id="パス_19" d="M61.726,465.96a9.9,9.9,0,0,0,3.669,1.87,5.011,5.011,0,0,0-.923,1.026,9.06,9.06,0,0,1-1.259-.529v4.094H65.18v1.123H54.338v-1.123h1.978V468.3a10.5,10.5,0,0,1-1.355.594,5.475,5.475,0,0,0-.887-.993,9.608,9.608,0,0,0,3.562-1.946H54.409v-1.112H59v-1.2h1.4v1.2H65.1v1.112Zm1.355,2.3a9.8,9.8,0,0,1-2.675-1.988v1.7H59v-1.739a9.78,9.78,0,0,1-2.6,2.031Zm-1.344,1.048H57.708v.432h4.029Zm-4.029,1.772h4.029v-.455H57.708Zm4.029,1.339v-.454H57.708v.454Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
              <path id="パス_20" d="M57.424,503.558v-1.341h1.489v-6.451H57.424v-1.353h4.635v1.353H60.583v6.451h1.476v1.341Z" transform="translate(-54.05 -434.849)" fill="#fff"/>
            </g>
          </g>
        </g>

      </g>

      <g id="コンポーネント_4_8" transform="translate(453 603.582)">
        <rect id="長方形_119-9" width="9" height="8" fill="#1c4817"/>
        <text id="語-18" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
      </g>
      <g id="コンポーネント_4_9" transform="translate(453 615.582)">
        <rect id="長方形_119-10" width="9" height="8" fill="#1c4817"/>
        <text id="語-19" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">語</tspan></text>
      </g>

    </g><!-- グループ_1814 -->
    <!-- ↑セクション３－１ -->

    <g id="コンポーネント_3_2" transform="translate(453 440)">
      <rect id="長方形_119-11" width="9" height="9" fill="#209115"/>
      <text id="文-7" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>
    <g id="コンポーネント_3_3" transform="translate(453 453)">
      <rect id="長方形_119-12" width="9" height="9" fill="#209115"/>
      <text id="文-8" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>
    <g id="コンポーネント_3_4" transform="translate(453 464)">
      <rect id="長方形_119-13" width="9" height="9" fill="#209115"/>
      <text id="文-9" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>
    <g id="コンポーネント_3_5" transform="translate(453 561)">
      <rect id="長方形_119-14" width="9" height="9" fill="#209115"/>
      <text id="文-10" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>
    <g id="コンポーネント_3_6" transform="translate(453 572)">
      <rect id="長方形_119-15" width="9" height="9" fill="#209115"/>
      <text id="文-11" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>
    <g id="コンポーネント_3_7" transform="translate(453 585)">
      <rect id="長方形_119-16" width="9" height="9" fill="#209115"/>
      <text id="文-12" transform="translate(4 6)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="-2.5" y="0">文</tspan></text>
    </g>

    <!-- ↓セクション３－２ -->
    <g id="グループ_1826" transform="translate(0 -6)">

      <g id="グループ_1803" transform="translate(9525.5 5755.819)">
        <g id="長方形_386" transform="translate(-9499 -5123)" fill="none" stroke="#28759d" stroke-width="2">
          <rect width="539" height="148.362" rx="14" stroke="none"/>
          <rect x="1" y="1" width="537" height="146.362" rx="13" fill="none"/>
        </g>
        <g id="グループ_1681" transform="translate(-9542.409 -5696.982)">
          <path id="パス_31" d="M73.254,632.669a432.2,432.2,0,0,0,9.654,88.3H54.566c-3.226,0-7.86-6.766-7.86-9.12V583.126c0-1.16-.033.343,1.112-2.488.964-2.391,5.113-6.825,6.748-6.825h4.148l24.194-1.206S73.254,585.36,73.254,632.669Z" transform="translate(-2.178 1.376)" fill="#28759d"/>
          <g id="グループ_538" transform="translate(52.331 613.809)">
            <g id="グループ_537" transform="translate(-1 2)">
              <path id="パス_32" d="M62.745,616.167a4.161,4.161,0,0,0,2.746,1.857,3.07,3.07,0,0,0-.815,1.07,5,5,0,0,1-2.866-2.129,5.088,5.088,0,0,1-3.227,2.14,4.934,4.934,0,0,0-.755-.951c1.835-.519,2.639-1.221,2.974-1.987H58.607v-2.983H61.03v-.691h-1.5v-.551a7.7,7.7,0,0,1-.984.529,4.473,4.473,0,0,0-.348-.626v.519H57.108v.518c.324.378,1.163,1.535,1.343,1.8l-.72.994c-.131-.313-.371-.778-.623-1.231v4.591H55.789v-3.976a8.273,8.273,0,0,1-1.092,2.074,6.06,6.06,0,0,0-.647-1.144,10.125,10.125,0,0,0,1.655-3.63H54.326v-1.189h1.463V608.96h1.319v2.215H58.2v.075a7.2,7.2,0,0,0,2.783-2.279h1.3a7.507,7.507,0,0,0,3.3,2.366,7.205,7.205,0,0,0-.731,1.123,9.6,9.6,0,0,1-.948-.5v.53H62.313v.691h2.555v2.983Zm-1.727-.984c.012-.119.012-.248.012-.367v-.649H59.843v1.016Zm2.1-3.749a7.661,7.661,0,0,1-1.451-1.361,7.224,7.224,0,0,1-1.392,1.361Zm-.8,2.733v.681c0,.109,0,.227-.011.335h1.283v-1.016Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
              <path id="パス_33" d="M61.726,640.072a9.917,9.917,0,0,0,3.669,1.869,5.012,5.012,0,0,0-.923,1.026,8.936,8.936,0,0,1-1.259-.529v4.1H65.18v1.123H54.338v-1.123h1.978v-4.116a10.631,10.631,0,0,1-1.355.593,5.474,5.474,0,0,0-.887-.993,9.607,9.607,0,0,0,3.562-1.945H54.409v-1.113H59v-1.2h1.4v1.2H65.1v1.113Zm1.355,2.3a9.8,9.8,0,0,1-2.675-1.989v1.7H59v-1.74a9.8,9.8,0,0,1-2.6,2.032Zm-1.344,1.047H57.708v.432h4.029Zm-4.029,1.772h4.029v-.454H57.708Zm4.029,1.34v-.454H57.708v.454Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
              <path id="パス_34" d="M55.685,677.669v-1.34h1.558v-6.452H55.685v-1.353h8.126v1.353H62.253v6.452h1.558v1.34Zm4.94-7.792H58.871v6.452h1.754Z" transform="translate(-54.05 -608.96)" fill="#fff"/>
            </g>
          </g>
        </g>
      </g><!-- グループ_1803 -->

      <!-- ↓セクション３－２ 左 -->
      <g id="グループ_1810" transform="translate(9588.5 6275)">

        <rect id="長方形_423-3" width="210" height="15" transform="translate(-9499 -5623)" fill="#f3f3f3"/>
        <g id="グループ_1740-3" transform="translate(337 -34)">
          <g id="グループ_1739-3" transform="translate(167 189)">
            <path id="パス_222-29" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            <path id="パス_222-30" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
            <path id="パス_222-31" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
            <path id="パス_222-32" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
            <path id="パス_222-33" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
            <path id="パス_222-34" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
            <path id="パス_222-35" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
            <path id="パス_222-36" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
            <path id="パス_222-37" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
            <path id="パス_223-3" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
          </g>
        </g>

        <g>
          <path id="パス_226-3" d="M0,0H209" transform="translate(-9499 -5595.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_227-3" d="M0,0H209" transform="translate(-9499 -5583.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_240-3" d="M0,0H209" transform="translate(-9499 -5607.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_228-3" d="M0,0H209" transform="translate(-9499 -5571.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_229-3" d="M0,0H209" transform="translate(-9499 -5559.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_230-3" d="M0,0H209" transform="translate(-9499 -5547.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_231-3" d="M0,0H209" transform="translate(-9499 -5535.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_232-3" d="M0,0H209" transform="translate(-9499 -5523.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_233-3" d="M0,0H209" transform="translate(-9499 -5511.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          <path id="パス_234-3" d="M0,0H209" transform="translate(-9499 -5499.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
        </g>

        <g id="グループ_1742-2" transform="translate(333 -33)">
          <rect id="長方形_80-31" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_2-5" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">2</tspan></text>
        </g>
        <g id="グループ_1764-2" transform="translate(333 -44.893)">
          <rect id="長方形_80-32" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_1-32" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">1</tspan></text>
        </g>
        <g id="グループ_1743-2" transform="translate(333 -21)">
          <rect id="長方形_80-33" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_3-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">3</tspan></text>
        </g>
        <g id="グループ_1744-2" transform="translate(333 -9)">
          <rect id="長方形_80-34" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_4-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">4</tspan></text>
        </g>
        <g id="グループ_1745-2" transform="translate(333 3)">
          <rect id="長方形_80-35" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_5-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">5</tspan></text>
        </g>
        <g id="グループ_1746-2" transform="translate(333 15)">
          <rect id="長方形_80-36" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_6-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">6</tspan></text>
        </g>
        <g id="グループ_1747-2" transform="translate(333 27)">
          <rect id="長方形_80-37" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_7-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">7</tspan></text>
        </g>
        <g id="グループ_1748-2" transform="translate(333 39)">
          <rect id="長方形_80-38" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_8-3" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">8</tspan></text>
        </g>
        <g id="グループ_1749-2" transform="translate(333 51)">
          <rect id="長方形_80-39" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_9-2" transform="translate(-9822 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">9</tspan></text>
        </g>
        <g id="グループ_1761-3" transform="translate(333 63)">
          <rect id="長方形_80-40" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
          <text id="_10-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">10</tspan></text>
        </g>

        <line id="線_557-3" x2="209" transform="translate(-9498.5 -5622.5)" fill="none" stroke="#000" stroke-width="1"/>

        <g transform="translate(-9502 -5626)">
          <text id="番号"     transform="translate(8 0)"   class="YuGothicUI_size7_1_spacing05"><tspan>番号</tspan></text>
          <text id="出題項目" transform="translate(32 0)"  class="YuGothicUI_size7_1_spacing05"><tspan>出題項目</tspan></text>
          <text id="配点"     transform="translate(140 0)" class="YuGothicUI_size7_1_spacing05"><tspan>配点</tspan></text>
          <text id="正誤"     transform="translate(167 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正誤</tspan></text>
          <text id="正答率"   transform="translate(190 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正答率</tspan></text>
        </g>

        <g id="グループ_1835" transform="translate(18.5)">
          <text id="_54-2" transform="translate(-9326 -5511)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][9]['正答率'] }}</tspan></text>
          <text id="_72-3" transform="translate(-9326 -5523)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][8]['正答率'] }}</tspan></text>
          <text id="_33-5" transform="translate(-9326 -5535)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][7]['正答率'] }}</tspan></text>
          <text id="_13-4" transform="translate(-9326 -5547)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][6]['正答率'] }}</tspan></text>
          <text id="_92-3" transform="translate(-9326 -5559)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][5]['正答率'] }}</tspan></text>
          <text id="_45-3" transform="translate(-9326 -5571)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][4]['正答率'] }}</tspan></text>
          <text id="_83-3" transform="translate(-9326 -5583)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][3]['正答率'] }}</tspan></text>
          <text id="_22-4" transform="translate(-9326 -5595)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][2]['正答率'] }}</tspan></text>
          <text id="_41-2" transform="translate(-9326 -5607)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][1]['正答率'] }}</tspan></text>
          <text id="_56-2" transform="translate(-9326 -5619)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][0]['正答率'] }}</tspan></text>
        </g>

        <g id="グループ_1809" transform="translate(17)">
          <text id="_9-3"  transform="translate(-9350 -5511)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][9]['正誤マーク'] }}</tspan></text>
          <text id="_10-4" transform="translate(-9350 -5523)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][8]['正誤マーク'] }}</tspan></text>
          <text id="_11-2" transform="translate(-9350 -5535)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][7]['正誤マーク'] }}</tspan></text>
          <text id="〇-19" transform="translate(-9350 -5547)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][6]['正誤マーク'] }}</tspan></text>
          <text id="〇-20" transform="translate(-9350 -5559)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][5]['正誤マーク'] }}</tspan></text>
          <text id="〇-21" transform="translate(-9350 -5571)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][4]['正誤マーク'] }}</tspan></text>
          <text id="〇-22" transform="translate(-9350 -5583)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][3]['正誤マーク'] }}</tspan></text>
          <text id="〇-23" transform="translate(-9350 -5595)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][2]['正誤マーク'] }}</tspan></text>
          <text id="_12-2" transform="translate(-9350 -5607)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][1]['正誤マーク'] }}</tspan></text>
          <text id="〇-24" transform="translate(-9350 -5619)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.0" y="8">{{ section32DataSet['rows'][0]['正誤マーク'] }}</tspan></text>
        </g>

        <g id="グループ_1807" transform="translate(16 -0.5)">
          <text id="_13-5" transform="translate(-9372 -5511)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_14-2" transform="translate(-9372 -5523)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_1-33" transform="translate(-9372 -5535)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_15-2" transform="translate(-9372 -5547)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">2</tspan></text>
          <text id="_16-2" transform="translate(-9372 -5559)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_17-2" transform="translate(-9372 -5571)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_18-2" transform="translate(-9372 -5583)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_19-2" transform="translate(-9372 -5595)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-34" transform="translate(-9372 -5607)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
          <text id="_1-35" transform="translate(-9372 -5619)" class="SegoeUI_size7_1_spacing05"><tspan x="0.086" y="8">1</tspan></text>
        </g>

        <g id="グループ_1805" transform="translate(34)">
          <g id="グループ_1773" transform="translate(204 -105)">
            <rect id="長方形_422-15" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1774-2" transform="translate(204 -117)">
            <rect id="長方形_422-16" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-2" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1775-2" transform="translate(204 -129)">
            <rect id="長方形_422-17" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-3" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1776-2" transform="translate(204 -141)">
            <rect id="長方形_422-18" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-4" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1781-2" transform="translate(204 -201)">
            <rect id="長方形_422-19" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-5" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1923" transform="translate(204 -189)">
            <rect id="長方形_422-20" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-6" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1924" transform="translate(204 -177)">
            <rect id="長方形_422-21" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-7" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1925" transform="translate(204 -165)">
            <rect id="長方形_422-22" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-8" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1926" transform="translate(204 -153)">
            <rect id="長方形_422-23" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-9" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
          <g id="グループ_1782-2" transform="translate(204 -213)">
            <rect id="長方形_422-24" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
            <text id="数-10" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
          </g>
        </g>

        <text id="四則の計算_四則の計算_四則の計算_四則の計算_四則の計算_金銭の貸借_仕事算_表の読み取り_表の読み取り_表の読み取り"
              transform="translate(-9473 -5610)" class="Meiryo_size8_33_spacing05">
          <tspan x="0" y="0">四則の計算</tspan>
          <tspan x="0" y="12">四則の計算</tspan>
          <tspan x="0" y="24">四則の計算</tspan>
          <tspan x="0" y="36">四則の計算</tspan>
          <tspan x="0" y="48">四則の計算</tspan>
          <tspan x="0" y="60">金銭の貸借</tspan>
          <tspan x="0" y="72">仕事算</tspan>
          <tspan x="0" y="84">表の読み取り</tspan>
          <tspan x="0" y="96">表の読み取り</tspan>
          <tspan x="0" y="108">表の読み取り</tspan>
        </text>

      </g><!-- グループ_1810 -->
      <!-- ↑セクション３－２ 左 -->

      <!-- ↓セクション３－２ 右 -->
      <g id="グループ_1825" transform="translate(10275 5685)">
        <g id="グループ_1824">

          <rect id="長方形_423-4" width="210" height="15" transform="translate(-9951.5 -5033)" fill="#f3f3f3"/>
          <g id="グループ_1740-4" transform="translate(-115.5 556)">
            <g id="グループ_1739-4" transform="translate(167 189)">
              <path id="パス_222-38" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
              <path id="パス_222-39" d="M0,0H209V13H0Z" transform="translate(-10003 -5751)" fill="#f3f3f3"/>
              <path id="パス_222-40" d="M0,0H209V13H0Z" transform="translate(-10003 -5739)" fill="#fff"/>
              <path id="パス_222-41" d="M0,0H209V13H0Z" transform="translate(-10003 -5727)" fill="#f3f3f3"/>
              <path id="パス_222-42" d="M0,0H209V13H0Z" transform="translate(-10003 -5715)" fill="#fff"/>
              <path id="パス_222-43" d="M0,0H209V13H0Z" transform="translate(-10003 -5703)" fill="#f3f3f3"/>
              <path id="パス_222-44" d="M0,0H209V13H0Z" transform="translate(-10003 -5691)" fill="#fff"/>
              <path id="パス_222-45" d="M0,0H209V13H0Z" transform="translate(-10003 -5679)" fill="#f3f3f3"/>
              <path id="パス_222-46" d="M0,0H209V13H0Z" transform="translate(-10003 -5667)" fill="#fff"/>
              <path id="パス_223-4" d="M0,0H209V13H0Z" transform="translate(-10003 -5763)" fill="#fff"/>
            </g>
          </g>

          <g>
            <path id="パス_226-4" d="M0,0H209" transform="translate(-9951.5 -5005.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_227-4" d="M0,0H209" transform="translate(-9951.5 -4993.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_240-4" d="M0,0H209" transform="translate(-9951.5 -5017.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_228-4" d="M0,0H209" transform="translate(-9951.5 -4981.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_229-4" d="M0,0H209" transform="translate(-9951.5 -4969.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_230-4" d="M0,0H209" transform="translate(-9951.5 -4957.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_231-4" d="M0,0H209" transform="translate(-9951.5 -4945.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_232-4" d="M0,0H209" transform="translate(-9951.5 -4933.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_233-4" d="M0,0H209" transform="translate(-9951.5 -4921.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
            <path id="パス_234-4" d="M0,0H209" transform="translate(-9951.5 -4909.5)" fill="none" stroke="#000" stroke-width="0.5" stroke-dasharray="2"/>
          </g>

          <g id="グループ_1761-4" transform="translate(-119.5 653)">
            <rect id="長方形_80-41" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_20-2" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">20</tspan></text>
          </g>
          <g id="グループ_1815" transform="translate(-119.5 641)">
            <rect id="長方形_80-42" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_19-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">19</tspan></text>
          </g>
          <g id="グループ_1816" transform="translate(-119.5 629)">
            <rect id="長方形_80-43" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_18-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">18</tspan></text>
          </g>
          <g id="グループ_1817" transform="translate(-119.5 617)">
            <rect id="長方形_80-44" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_17-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">17</tspan></text>
          </g>
          <g id="グループ_1818" transform="translate(-119.5 605)">
            <rect id="長方形_80-45" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_16-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">16</tspan></text>
          </g>
          <g id="グループ_1819" transform="translate(-119.5 593)">
            <rect id="長方形_80-46" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_15-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">15</tspan></text>
          </g>
          <g id="グループ_1820" transform="translate(-119.5 581)">
            <rect id="長方形_80-47" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_14-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">14</tspan></text>
          </g>
          <g id="グループ_1821" transform="translate(-119.5 569)">
            <rect id="長方形_80-48" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_13-6" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">13</tspan></text>
          </g>
          <g id="グループ_1822" transform="translate(-119.5 557)">
            <rect id="長方形_80-49" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_12-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">12</tspan></text>
          </g>
          <g id="グループ_1823" transform="translate(-119.5 545)">
            <rect id="長方形_80-50" width="18.64" height="7.893" rx="3.946" transform="translate(-9829.32 -5572.5)" fill="#28759d"/>
            <text id="_11-3" transform="translate(-9824 -5573)" fill="#fff" class="SegoeUI_size6_86_spacing05"><tspan y="7">11</tspan></text>
          </g>

          <line id="線_557-4" x2="209" transform="translate(-9951 -5032.5)" fill="none" stroke="#000" stroke-width="1"/>

          <g transform="translate(-9955 -5036)">
            <text id="番号"     transform="translate(8 0)"   class="YuGothicUI_size7_1_spacing05"><tspan>番号</tspan></text>
            <text id="出題項目" transform="translate(33 0)"  class="YuGothicUI_size7_1_spacing05"><tspan>出題項目</tspan></text>
            <text id="配点"     transform="translate(146 0)" class="YuGothicUI_size7_1_spacing05"><tspan>配点</tspan></text>
            <text id="正誤"     transform="translate(169 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正誤</tspan></text>
            <text id="正答率"   transform="translate(190 0)" class="YuGothicUI_size7_1_spacing05"><tspan>正答率</tspan></text>
          </g>

          <g id="グループ_1836" transform="translate(17.5 0.5)">
            <text id="_49-2" transform="translate(-9778.5 -4921)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][19]['正答率'] }}</tspan></text>
            <text id="_50-2" transform="translate(-9778.5 -4933)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][18]['正答率'] }}</tspan></text>
            <text id="_72-4" transform="translate(-9778.5 -4945)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][17]['正答率'] }}</tspan></text>
            <text id="_33-6" transform="translate(-9778.5 -4957)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][16]['正答率'] }}</tspan></text>
            <text id="_13-7" transform="translate(-9778.5 -4969)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][15]['正答率'] }}</tspan></text>
            <text id="_92-4" transform="translate(-9778.5 -4981)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][14]['正答率'] }}</tspan></text>
            <text id="_45-4" transform="translate(-9778.5 -4993)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][13]['正答率'] }}</tspan></text>
            <text id="_83-4" transform="translate(-9778.5 -5005)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][12]['正答率'] }}</tspan></text>
            <text id="_82-2" transform="translate(-9778.5 -5017)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][11]['正答率'] }}</tspan></text>
            <text id="_34-2" transform="translate(-9778.5 -5029)" class="SegoeUI_size7_1_spacing05"><tspan x="1.995" y="8">{{ section32DataSet['rows'][10]['正答率'] }}</tspan></text>
          </g>

          <g id="グループ_1809-2" transform="translate(-434.5 590.5)">
            <text id="〇-25" transform="translate(-9350 -5511)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][19]['正誤マーク'] }}</tspan></text>
            <text id="〇-26" transform="translate(-9350 -5523)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][18]['正誤マーク'] }}</tspan></text>
            <text id="_20-3" transform="translate(-9350 -5535)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][17]['正誤マーク'] }}</tspan></text>
            <text id="〇-27" transform="translate(-9350 -5547)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][16]['正誤マーク'] }}</tspan></text>
            <text id="〇-28" transform="translate(-9350 -5559)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][15]['正誤マーク'] }}</tspan></text>
            <text id="_21-3" transform="translate(-9350 -5571)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][14]['正誤マーク'] }}</tspan></text>
            <text id="〇-29" transform="translate(-9350 -5583)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][13]['正誤マーク'] }}</tspan></text>
            <text id="〇-30" transform="translate(-9350 -5595)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][12]['正誤マーク'] }}</tspan></text>
            <text id="〇-31" transform="translate(-9350 -5607)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][11]['正誤マーク'] }}</tspan></text>
            <text id="_22-5" transform="translate(-9350 -5619)" class="SegoeUI_size7_1_spacing05" text-anchor="middle"><tspan x="6.5" y="8">{{ section32DataSet['rows'][10]['正誤マーク'] }}</tspan></text>
          </g>

          <g id="グループ_1807-2" transform="translate(-431.5 590)">
            <text id="_2-6"  transform="translate(-9372 -5511)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_2-7"  transform="translate(-9372 -5523)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_2-8"  transform="translate(-9372 -5535)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_1-36" transform="translate(-9372 -5547)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">1</tspan></text>
            <text id="_1-37" transform="translate(-9372 -5559)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">1</tspan></text>
            <text id="_23-2" transform="translate(-9372 -5571)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_24-2" transform="translate(-9372 -5583)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_2-9"  transform="translate(-9372 -5595)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
            <text id="_25-2" transform="translate(-9372 -5607)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">1</tspan></text>
            <text id="_2-10" transform="translate(-9372 -5619)" class="SegoeUI_size7_1_spacing05"><tspan x="1.0" y="8">2</tspan></text>
          </g>

          <g id="グループ_1805-2" transform="translate(-413.5 590)">
            <g id="グループ_1773-2" transform="translate(204 -105)">
              <rect id="長方形_422-25" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
              <text id="論" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">論</tspan></text>
            </g>
            <g id="グループ_1774-3" transform="translate(204 -117)">
              <rect id="長方形_422-26" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
              <text id="論-2" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">論</tspan></text>
            </g>
            <g id="グループ_1775-3" transform="translate(204 -129)">
              <rect id="長方形_422-27" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
              <text id="数-11" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
            </g>
            <g id="グループ_1776-3" transform="translate(204 -141)">
              <rect id="長方形_422-28" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
              <text id="数-12" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
            </g>
            <g id="グループ_1777-2" transform="translate(204 -153)">
              <rect id="長方形_422-29" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
              <text id="数-13" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
            </g>
            <g id="グループ_1778-2" transform="translate(204 -189)">
              <rect id="長方形_422-30" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
              <text id="数-14" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
            </g>
            <g id="グループ_1779-2" transform="translate(204 -201)">
              <rect id="長方形_422-31" width="9" height="9" transform="translate(-9612 -5405)" fill="#c22c2c"/>
              <text id="数-15" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">数</tspan></text>
            </g>
            <g id="グループ_1780-2" transform="translate(204 -177)">
              <rect id="長方形_422-32" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
              <text id="論-3" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">論</tspan></text>
            </g>
            <g id="グループ_1927" transform="translate(204 -165)">
              <rect id="長方形_422-33" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
              <text id="論-4" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">論</tspan></text>
            </g>
            <g id="グループ_1782-3" transform="translate(204 -213)">
              <rect id="長方形_422-34" width="9" height="9" transform="translate(-9612 -5405)" fill="#f90"/>
              <text id="論-5" transform="translate(-9610 -5405)" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan y="6">論</tspan></text>
            </g>
          </g>

          <text id="推論_内訳_速度の計算_旅人算_推論_順序_推論_順序_割合の計算_損益算_損益算_推論_論理_推論_論理_"
                transform="translate(-9924.5 -5020)" class="Meiryo_size8_33_spacing05">
            <tspan x="0" y="0">推論（内訳）</tspan>
            <tspan x="0" y="12">速度の計算</tspan>
            <tspan x="0" y="24">旅人算</tspan>
            <tspan x="0" y="36">推論（順序）</tspan>
            <tspan x="0" y="48">推論（順序）</tspan>
            <tspan x="0" y="60">割合の計算</tspan>
            <tspan x="0" y="72">損益算</tspan>
            <tspan x="0" y="84">損益算</tspan>
            <tspan x="0" y="96">推論（論理）</tspan>
            <tspan x="0" y="108">推論（論理）</tspan>
          </text>

        </g><!-- グループ_1824 -->
      </g><!-- グループ_1825 -->

    </g><!-- グループ_1826 -->
    <!-- ↑セクション３－２ -->

    <!-- ↓セクション３ 凡例 -->
    <g id="グループ_1834" transform="translate(0 789)">

      <text id="_正誤判定のNは無選択の場合です_" transform="translate(30 0)">
        <tspan class="SegoeUISymbol_size7_spacing01">★</tspan>
        <tspan class="YuGothicUI_size7_spacing01">正誤判定のNは無選択の場合です。</tspan>
      </text>

      <text id="_正答率は_全国を基準としています_" transform="translate(145 0)">
        <tspan class="SegoeUISymbol_size7_spacing01">★</tspan>
        <tspan class="YuGothicUI_size7_spacing01">正答率は、全国を基準としています。</tspan>
      </text>

      <g id="グループ_1830" transform="translate(265 0)">
        <rect id="長方形_422-35" x="0" y="-7" width="9" height="9" fill="#1c4817"/>
        <text id="語-20" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="2" y="-1">語</tspan></text>
        <text id="_語いに配点"><tspan x="10" class="YuGothicUI_size7_spacing01">語いに配点</tspan></text>
      </g>

      <g id="グループ_1833" transform="translate(320 0)">
        <rect id="長方形_422-36" x="0" y="-7" width="9" height="9" fill="#209115"/>
        <text id="文-6" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="2" y="-1">文</tspan></text>
        <text id="_文章読解に配点"><tspan x="10" class="YuGothicUI_size7_spacing01">文章読解に配点</tspan></text>
      </g>

      <g id="グループ_1831" transform="translate(395 0)">
        <rect id="長方形_422-37" x="0" y="-7" width="9" height="9" fill="#c22c2c"/>
        <text id="数-16" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="2" y="-1">数</tspan></text>
        <text id="_数的能力に配点"><tspan x="10" class="YuGothicUI_size7_spacing01">数的能力に配点</tspan></text>
      </g>

      <g id="グループ_1832" transform="translate(465 0)">
        <rect id="長方形_422-38" x="0" y="-7" width="9" height="9" fill="#f90"/>
        <text id="論-6" fill="#fff" class="SourceHanSansBold_size5_weight700_spacing05"><tspan x="2" y="-1">論</tspan></text>
        <text id="_論理に配点"><tspan x="10" class="YuGothicUI_size7_spacing01">論理に配点</tspan></text>
      </g>

    </g><!-- グループ_1834 -->
    <!-- ↑セクション３ 凡例 -->

    <!-- ↓セクション４ -->
    <g>

      <path id="パス_247" d="M5.353,0H132.3c2.956,0,5.353,1.9,5.353,4.252V16.074c0,2.348-2.4,4.252-5.353,4.252H5.353C2.4,20.326,0,18.423,0,16.074V4.252C0,1.9,2.4,0,5.353,0Z" transform="translate(29.549 808)" fill="#28759d"/>
      <text id="あなたの弱点ポイント" transform="translate(93.049 822.163)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size10_5_spacing05"><tspan x="-45.731">あなたの弱点ポイント</tspan></text>

      <g id="長方形_435" transform="translate(30 843)" fill="none" stroke="#28759d" stroke-width="1">
        <rect width="157" height="68" stroke="none"/>
        <rect x="0.5" y="0.5" width="156" height="67" fill="none"/>
      </g>

      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#パス_249)">
        <path id="パス_249-2" d="M4.635,0h31.73A4.47,4.47,0,0,1,41,4.285V9.558a4.47,4.47,0,0,1-4.635,4.285H4.635A4.47,4.47,0,0,1,0,9.558V4.285A4.47,4.47,0,0,1,4.635,0Z" transform="translate(39.8 853.32)" fill="#4e99ee"/>
      </g>
      <text id="言語" transform="translate(51.617 864.316)" fill="#fff" class="YuGothicUI_size8_33_spacing05"><tspan>言語</tspan></text>
      <text id="文章読解_内容一致_" transform="translate(88 864)" class="YuGothicUI_size10_5_spacing05"><tspan>{{ section4DataSet['弱点ポイント１'] }}</tspan></text>

      <line id="線_588" x2="139.7" transform="translate(39.8 877)" fill="none" stroke="#28759d" stroke-width="1"/>

      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#パス_250)">
        <path id="パス_250-2" d="M4.635,0h31.73A4.47,4.47,0,0,1,41,4.285V9.558a4.47,4.47,0,0,1-4.635,4.285H4.635A4.47,4.47,0,0,1,0,9.558V4.285A4.47,4.47,0,0,1,4.635,0Z" transform="translate(39.8 886.68)" fill="#4e99ee"/>
      </g>
      <text id="非言語" transform="translate(47.049 896.684)" fill="#fff" class="YuGothicUI_size8_33_spacing05"><tspan>非言語</tspan></text>
      <text id="四則の計算" transform="translate(90 897)" class="YuGothicUI_size10_5_spacing05"><tspan>{{ section4DataSet['弱点ポイント２'] }}</tspan></text>

      <path id="パス_248" d="M12.979,0H320.8c7.168,0,12.979,1.9,12.979,4.252V16.074c0,2.348-5.811,4.252-12.979,4.252H12.979C5.811,20.326,0,18.423,0,16.074V4.252C0,1.9,5.811,0,12.979,0Z" transform="translate(210.549 808)" fill="#28759d"/>
      <text id="学習アドバイス" transform="translate(255.049 822.5)" fill="#fff" stroke="#fff" stroke-width="0.4" class="YuGothicUI_size10_5_spacing05"><tspan x="-31.819">学習アドバイス</tspan></text>
      <text id="_アドバイスは得点_素点_ではなく偏差値をもとに作成しています" transform="translate(296 823)" fill="#fff">
        <tspan class="SegoeUISymbol_size8_33_spacing05">※</tspan>
        <tspan class="YuGothicUI_size8_33_spacing05">アドバイスは得点（素点）ではなく偏差値をもとに作成しています</tspan>
      </text>

      <g id="長方形_434" transform="translate(211 845)" fill="#fff" stroke="#639bb8" stroke-width="1">
        <rect width="338" height="66" stroke="none"/>
        <rect x="0.5" y="0.5" width="337" height="65" fill="none"/>
      </g>

      <!-- 学習アドバイス（前半＋後半） ※foreignObjectを利用している -->
      <foreignObject x="220" y="855" width="320" height="50">
        <html xmlns="http://www.w3.org/1999/xhtml">
          <div style="background-color:white; width:100%; height:100%;" class="YuGothicUI_size8_33_spacing05">
            {{ section4DataSet['学習アドバイス'][0] }}{{ section4DataSet['学習アドバイス'][1] }}
          </div>
        </html>
      </foreignObject>

    </g>
    <!-- ↑セクション４ -->

  </g>
</svg>

    </div><!-- report_body -->
    <div v-else class="practice_report_body">

      <table class="summery_table">
        <tr>
          <th class="correct_count my_h6">正解数</th>
          <td class="correct_count">{{ section1DataSet['正解数'] }}</td>
          <th class="correct_rate my_h6">正解率</th>
          <td class="correct_rate">{{ section1DataSet['正解率'] }}%</td>
        </tr>
      </table>

      <table class="question_table mt-3">
        <thead>
          <tr>
            <th class="question_no my_h6">問題番号</th>
            <th class="unit my_h6">出題項目</th>
            <th class="correct_answer my_h6">正解</th>
            <th class="your_answer"><span class="my_h6" style="margin-right: 1em;">あなたの解答</span><br class="d-md-none" /><span class="small">※Nは無回答</span></th>
            <th class="correct_mark my_h6">正誤</th>
          </tr>
        </thead>
        <tbody>
          <tr class="section31" v-for="(row, idx) in section31DataSet['rows']" :key="`row_1_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
          <tr class="section32" v-for="(row, idx) in section32DataSet['rows']" :key="`row_2_${idx}`">
            <td class="question_no">{{ row['問題番号'] }}</td>
            <td class="unit my_h6">{{ row['出題項目'] }}</td>
            <td class="correct_answer my_h6">{{ row['正解'].join('、') }}</td>
            <td class="your_answer my_h6">{{ row['あなたの解答'].length? row['あなたの解答'].map(value => value? value: 'N').join('、'): 'N' }}</td>
            <td class="correct_mark">{{ row['正誤マーク_2'] }}</td>
          </tr>
        </tbody>
      </table>

    </div><!-- practice_report_body -->

  </div><!-- report_wrapper -->
</template>

<script>
import baseMixin from '../personal_report_base';
const LogHeader = 'PersonalReport_WA';

export default {
  props: {
  },
  mixins: [ baseMixin ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    const dev80_base_pos = 60.5;//調整用の偏差値80の枠が台紙の偏差値80の枠と重なるようにする
    const dev20_base_pos = 18.0;//調整用の偏差値20の枠が台紙の偏差値20の枠と重なるようにする
    const dev1_len = (dev80_base_pos - dev20_base_pos) / (80 - 20);
    return {
      radar_chart: {
        center_x: 60.0,//レーダーチャートの全体的な左右のズレはここで調整する
        center_y: 61.8,//レーダーチャートの全体的な上下のズレはここで調整する
        dev20_base_pos,
        dev1_len,
        line_width: 1.8,
      },
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section1DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']; },
    section2DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']; },
    section31DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_1']; },
    section32DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_3_2']; },
    section4DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_4']; },
  },
  methods: {
    calcRadarChartLen(deviationScore) {
      return this.radar_chart? this.radar_chart.dev20_base_pos + (deviationScore - 20) * this.radar_chart.dev1_len: 0;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../../../sass/_base"

.report_wrapper

  .report_body
    svg
      display: block
      margin: 0 auto
      $orgWidth: 538.99
      $orgHeight: 790.99
      width: 84vw
      height: 84vw / $orgWidth * $orgHeight
      +mq(min_576)
        width: 80vw
        height: 80vw / $orgWidth * $orgHeight
      +mq(min_992)
        width: 60vw
        height: 60vw / $orgWidth * $orgHeight
      +mq(min_1200)
        width: 50vw
        height: 50vw / $orgWidth * $orgHeight

      .Meiryo_size7_spacing05
        font-family: Meiryo
        font-size: 7px
        letter-spacing: 0.05em

      .Meiryo_size8_33_spacing05
        font-family: Meiryo
        font-size: 8.33px
        letter-spacing: 0.05em

      .MeiryoBold_size10_weight700
        font-family: Meiryo-Bold, Meiryo
        font-size: 10px
        font-weight: 700

      .SegoeUI_size6_86_spacing05
        font-family: SegoeUI, Segoe UI
        font-size: 6.86px
        letter-spacing: 0.05em

      .SegoeUI_size7_spacing01
        font-family: SegoeUI, Segoe UI
        font-size: 7px
        letter-spacing: 0.01em

      .SegoeUI_size7_1_spacing05
        font-family: SegoeUI, Segoe UI
        font-size: 7.1px
        letter-spacing: 0.05em

      .SegoeUISymbol_size7_spacing01
        font-family: SegoeUISymbol, Segoe UI Symbol
        font-size: 7px
        letter-spacing: 0.01em

      .SegoeUISymbol_size8_33_spacing05
        font-family: SegoeUISymbol, Segoe UI Symbol
        font-size: 8.33px
        letter-spacing: 0.05em

      .SourceHanSansBold_size5_weight700_spacing05
        font-family: SourceHanSansJP-Bold, Source Han Sans JP
        font-size: 5px
        font-weight: 700
        letter-spacing: 0.05em

      .SourceHanSansBold_size9_weight700
        font-family: SourceHanSansJP-Bold, Source Han Sans JP
        font-size: 9px
        font-weight: 700

      .SourceHanSansBold_size9_weight700_spacing025
        font-family: SourceHanSansJP-Bold, Source Han Sans JP
        font-size: 9px
        font-weight: 700
        letter-spacing: 0.025em

      .SourceHanSansBold_size9_weight700_spacing05
        font-family: SourceHanSansJP-Bold, Source Han Sans JP
        font-size: 9px
        font-weight: 700
        letter-spacing: 0.05em

      .SourceHanSansBold_size11_weight700_spacing025
        font-family: SourceHanSansJP-Bold, Source Han Sans JP
        font-size: 11px
        font-weight: 700
        letter-spacing: 0.025em

      .YuGothicUI_size7_spacing01
        font-family: YuGothicUI-Regular, Yu Gothic UI
        font-size: 7px
        letter-spacing: 0.01em

      .YuGothicUI_size7_1_spacing05
        font-family: YuGothicUI-Regular, Yu Gothic UI
        font-size: 7.1px
        letter-spacing: 0.05em

      .YuGothicUI_size8_33_spacing05
        font-family: YuGothicUI-Regular, Yu Gothic UI
        font-size: 8.22px
        letter-spacing: 0.05em

      .YuGothicUI_size10_5_spacing05
        font-family: YuGothicUI-Regular, Yu Gothic UI
        font-size: 10.5px
        letter-spacing: 0.05em

      .YuGothicUI_size11_spacing05
        font-family: YuGothicUI-Regular, Yu Gothic UI
        font-size: 11px
        letter-spacing: 0.05em


  .practice_report_body //$questionBgColor1, $questionBgColor2 は試験種により異なるかも知れない
    $width: 100%
    .summery_table
      $bgColor: #A2E6FF
      width: $width
      th, td
        border: 1px solid black
        padding: 1em 0
        text-align: center
      th
        background-color: $bgColor
      th.correct_count
        width: 20%
      td.correct_count
        width: 25%
      th.correct_rate
        width: 20%
      td.correct_rate
        width: 25%
    .question_table
      $headerBgColor: #FFFFA2
      $questionBgColor1: #F6F6F6 //試験種により異なるかも知れない
      $questionBgColor2: #F6F6F6 //試験種により異なるかも知れない
      width: $width
      th, td
        border: 1px solid black
        text-align: center
      th
        background-color: $headerBgColor
      .section31
        td.question_no, td.unit
          background-color: $questionBgColor1
      .section32
        td.question_no, td.unit
          background-color: $questionBgColor2
      td.unit
        text-align: unset
        +mq(min_576)
          padding-left: 2em
      td.correct_mark
        color: red
      .question_no
        width: 15%
      .unit
        width: 25%
      .correct_answer
        width: 15%
      .your_answer
        width: 30%
      .correct_mark
        width: 15%

</style>
