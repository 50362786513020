<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WA-1 本テスト 検査２ p01</div>

    <!-- ↓ 画面レイアウトタイプＧ ※単独 ※WAオリジナルレイアウト -->

    <div class="row">

      <div class="col-12 px-3 mb-3">
        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no range">{{ params[questionIds[0]].q_no }}～{{ params[questionIds[4]].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params.s01" />
            </div>
          </div>
        </div>
      </div>

      <div v-for="(n, i) in 5" class="col-12">
        <div class="row ml-1 ml-md-3">

          <div class="col-12 col-md-6">
            <div class="question_table_wrapper">
              <div class="question_table">
                <div class="question_table_row d-flex align-items-center">
                  <div class="question_no">{{ params[questionIds[i]].q_no }}</div>
                  <div class="text_container_80">
                    <MyTextBox class="_question_str_90" :canEdit="canEdit" v-model="params[questionIds[i]].question" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="choice_table_wrapper ml-md-5">
              <div class="choice_table">
                <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[i]" :key="choiceValue">
                  <input type="checkbox" :id="`${questionIds[i]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                          v-model="params[questionIds[i]].answer"
                          :ref="`checkbox_q${questionIds[i]}`"
                          @click="onClickCheckbox(params[questionIds[i]], $refs[`checkbox_q${questionIds[i]}`], $event)"
                          @change="sortCheckboxAnswers(params[questionIds[i]])"
                  />
                  <label :for="`${questionIds[i]}_${choiceValue}`" class="choice_checkbox_placeholder has_value">
                    <div class="choice_value">{{ choiceValue }}：</div>
                  </label>
                  <label :for="`${questionIds[i]}_${choiceValue}`" class="">
                    <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[i]].choices[choiceIdx]" />
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div><!-- row -->
      </div><!-- v-for -->

    </div><!-- row -->

    <!-- ↑ 画面レイアウトタイプＧ -->

    <!-- ↓ 解説 ※全ページ共通コード -->
    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper bordered">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>
    <!-- ↑ 解説 -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WA-1/本テスト/検査２/p01';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q001', 'q002', 'q003', 'q004', 'q005' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
