const LogHeader = 'personal_report/personal_report_base';

export default {
  props: {
    schoolExamStudent: { default: null },
    scExStudentExamPartSet: { default: null },
    offsetTopForNavBar: { default: false },
  },
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return {
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
  },
  methods: {
    makePadded(value, digit, padding) { return `${(padding??' ').repeat(digit)}${value}`.substr(-digit).replace('/ /g', '&nbsp;'); },
    makePadded2Digits(value, padding) { return this.makePadded(value, 2, padding); },
    makePadded3Digits(value, padding) { return this.makePadded(value, 2, padding); },
    makePadded4Digits(value, padding) { return this.makePadded(value, 4, padding); },
  },
};
