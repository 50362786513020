import { render, staticRenderFns } from "./p13.vue?vue&type=template&id=6733598b&scoped=true&"
import script from "./p13.vue?vue&type=script&lang=js&"
export * from "./p13.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6733598b",
  null
  
)

export default component.exports