<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WA-1 練習２ 検査１ p09</div>

    <!-- ↓ 画面レイアウトタイプＦ -->

    <div class="text_container_80">
      <MyTextBox class="" :canEdit="canEdit" v-model="params.s01" />
    </div>

    <div class="row">
      <div class="col-12 px-3">

        <div class="question_table_wrapper">
          <div class="question_table">
            <div :class="`question_table_row ${questionId}`" v-for="questionId in questionIds" :key="questionId">
              <div class="question_no">{{ params[questionId].q_no }}</div>
              <MyTextBox class="question_str_90" :canEdit="canEdit" v-model="params[questionId].question" />
            </div>
          </div>
        </div>

        <div class="choice_table_wrapper ml-md-5">
          <div class="choice_table bg-lightyellow">
            <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
              <div class="choice_value text-red right_margin">{{ choiceValue }}</div>
              <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params.choices[choiceIdx]" />
            </div>
          </div>
        </div>

        <div class="checkbox_table_wrapper ml-md-5">
          <table class="checkbox_table">
            <tr>
              <td></td>
              <td v-for="choiceValue in choiceValues[0]" :key="choiceValue"><div class="choice_value">{{ choiceValue }}</div></td>
            </tr>
            <tr :class="questionId" v-for="questionId in questionIds" :key="questionId" :data-ans-value="params[questionId].answer">
              <td><div class="question_no">{{ params[questionId].q_no }}</div></td>
              <td v-for="choiceValue in choiceValues[0]" :key="choiceValue" :data-cb-value="choiceValue" :data-to-be-checked="choiceValue==params[questionId].answer">
                <input type="checkbox" :id="`${questionId}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                        v-model="params[questionId].answer"
                        :ref="`checkbox_${questionId}`"
                        @click="onClickCheckbox2(questionId, `checkbox_${questionId}`, $event, questionIds/*排他選択対応*/)"
                        @change="sortCheckboxAnswers(params[questionId])"
                />
                <label :for="`${questionId}_${choiceValue}`" class="choice_checkbox_placeholder"></label>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div><!-- row -->

    <!-- ↑ 画面レイアウトタイプＦ -->

    <!-- ↓ 解説 ※全ページ共通コード -->
    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper bordered">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>
    <!-- ↑ 解説 -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WA-1/練習２/検査１/p09';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q013', 'q014', 'q015' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
