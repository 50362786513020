<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">WA-1 練習１ 検査１ p08</div>

    <!-- ↓ 画面レイアウトタイプＡ -->

    <div class="text_container_80">
      <MyTextBox class="" :canEdit="canEdit" v-model="params.s01" />
    </div>

    <div class="row">
      <div class="col-12 px-3">

        <div class="question_table_wrapper">
          <div class="question_table">
            <div class="question_table_row">
              <div class="question_no">{{ params[questionIds[0]].q_no }}</div>
              <div class="text_container_80 bg-lightyellow">
                <MyTextBox class="_question_str_90" :canEdit="canEdit" v-model="params[questionIds[0]].question" />
              </div>
            </div>
          </div>
        </div>

        <div class="choice_table_wrapper ml-md-5">
          <div class="choice_table">
            <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
              <input type="checkbox" :id="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                      v-model="params[questionIds[0]].answer"
                      :ref="`checkbox_q${questionIds[0]}`"
                      @click="onClickCheckbox(params[questionIds[0]], $refs[`checkbox_q${questionIds[0]}`], $event)"
                      @change="sortCheckboxAnswers(params[questionIds[0]])"
              />
              <label :for="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox_placeholder has_value">
                <div class="choice_value">{{ choiceValue }}：</div>
              </label>
              <label :for="`${questionIds[0]}_${choiceValue}`" class="">
                <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[0]].choices[choiceIdx]" />
              </label>
            </div>
          </div>
        </div>

      </div>
    </div><!-- row -->

    <!-- ↑ 画面レイアウトタイプＡ -->

    <!-- ↓ 解説 ※全ページ共通コード -->
    <div v-if="showDescription" class="my-3">
      <hr />
      <div class="description_area_title my-2">【解説】</div>

      <div class="description_tables_wrapper bordered">
        <DescriptionTable :class="questionId" v-for="questionId in questionIds" :key="questionId">
          <template v-slot:q_no>{{ params[questionId].q_no }}</template>
          <template v-slot:answer>正解：{{ getDescAnswerStr(params[questionId]) }}</template>
          <template v-slot:description>
            <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
          </template>
        </DescriptionTable>
      </div>

    </div>
    <!-- ↑ 解説 -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'WA-1/練習１/検査１/p08';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      questionIds: [ 'q008' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
